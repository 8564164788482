import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Abouthero from "../components/hero/Abouthero";
import CTA1 from "../components/CTA1";
import CTA2 from "../components/CTA2";
import CTA3 from "../components/CTA3";
import Layout from "../components/Layout";
import Services from "../components/Services";
import Locations from "../components/Locations";

function About() {
  return (
    <Layout>
      <Abouthero />
      <Container>
        <Row>
          <Col lg={6}>
            <h3>Our Mission</h3>
            <p>
              "We strive to satisfy our clients with stellar customer service
              from the point of first contact, through the inspection, and into
              the future.
            </p>
            <p>
              With integrity and pride, we set the "Standard of Excellence" in
              Central Florida home inspections."
            </p>
          </Col>
          <Col lg={6}>
            <h3>Our Service Area</h3>
            <p>
              Serving: New Port Richey, Trinity, Port Richey, Hudson, Holiday,
              Spring Hill, Tampa, St. Petersburg, Clearwater Land O Lakes,
              Odessa, Oldsmar, Lutz, Tarpon Springs, Dunedin, Palm Harbor,
              Seminole, Largo, Wesley Chapel, West Chase, Carrollwood, Temple
              Terrance, Town-n-Country, Seminole Heights, Hyde Park, Davis
              Island, Harbor Island, Clearwater Beach, Belleair and Belleair
              Beach, Redington Beach, Madeira Beach, Treasure Island, St.Pete
              Beach, Tierra Verde, Weeki Wachee, Lakeland, , Brandon, Valrico,
              Riverview, Apollo Beach, Lakewood Ranch, Bradenton, Palmetto,
              Weeki Wachee, Homosassa, Crystal River and Others
            </p>
          </Col>
        </Row>
      </Container>
      <CTA2>
        <h3>The Best Home Inspection Company in Florida.</h3>
        <p className="smalltext">
          Need to know the truth before you buy or sell? Let an unbiased
          inspector find it out.
        </p>
      </CTA2>
      <Container>
        <Row>
          <Col className="mt-30">
            <h3>Our History</h3>
            <p>
              Young Home Inspections is a residential and commercial property
              inspection company serving New Port Richey and the Central Florida
              areas since 1989. We pride ourselves in our thorough,
              comprehensive, and easy to read inspections of homes, townhomes,
              condominiums, villas and commercial buildings. In fact, our
              company is the winner of the Coveted Angie's List{" "}
              <strong>"Super Service Award"</strong> for 20 Consecutive Years!
            </p>
            <p>
              Young Home Inspections is locally owned, operated and insured. We
              have a highly qualified staff that is extremely skilled and
              experienced in performing all types of inspections. Additionally,
              each of our inspection staff are State licensed and InterNachi
              Certified Inspectors who have the flexibility to fit your
              scheduling needs.
            </p>
            <p>
              Young Home Inspections was established in 1989 by Ken Young one of
              the innovators of the home inspection industry, who along with
              several colleagues re-wrote industry procedures on home
              inspections in Central Florida. Mr. Young and his colleagues
              thought that people deserved more out of a home inspection and so
              they changed the criteria for home inspections that are still
              practiced today. The home inspection became much more thorough and
              comprehensive then ever seen before. When Mr. Young first came on
              the scene with his new way of doing home inspections it wasn’t
              first met with approval, realtors coined him the “Deal Breaker”
              but being the charmer Mr. Young was he became a very popular
              figure in the home inspection industry featured in many newspaper
              articles and interviews. 30 years later people still remember this
              and sometimes bring it up to me with a grin! Mr. Young sadly
              passed away at an early age but his legacy continues. Our company
              which is still locally & family owned and operated is keeping up
              with Mr. Young's vision and trying in every way to make him proud
              today!
            </p>
            <p>
              We as the current owners of Young Home Inspections spent our
              entire careers in the construction, insurance, marketing & office
              management fields. We hold a State of Florida license as a
              Certified Building Contractor and a Home Inspector, and all of our
              Inspectors are also State licensed and certified by InterNachi as
              well . Continuing education is always a priority, we stay current
              with all new technology and education. All inspectors are
              certified to do any insurance report you might need , like wind
              mitigation and 4 Points and Roof Certifications. We have decades
              of experience and 10’s of thousands of home & building inspections
              completed.
            </p>
            <p>
              Our love for excellence and high quality work and the excellent
              methods pioneered by Ken Young have proven a great combination.
              Moving into the future, our team continues to work that legacy of
              excellence and the pioneering spirit that helps bring new
              innovations to the table for the industry and our clients.
            </p>
          </Col>
        </Row>
      </Container>
      <CTA3>
        <Container>
          <Row>
            <Col lg={3} md={6} sm={6} xs={12}>
              <p>
                Decks/
                <br />
                Patios
              </p>
              <div className="divider" />
              <p className="smalltext">INSPECTIONS</p>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <p>
                Pools/
                <br />
                Spas
              </p>
              <div className="divider" />
              <p className="smalltext">INSPECTIONS</p>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <p>
                Detached
                <br />
                Buildings
              </p>
              <div className="divider" />
              <p className="smalltext">INSPECTIONS</p>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <p>
                Sea Walls/
                <br />
                Docks
              </p>
              <div className="divider" />
              <p className="smalltext">INSPECTIONS</p>
            </Col>
          </Row>
        </Container>
      </CTA3>
      <Services />
      <CTA1 />
      <Locations />
    </Layout>
  );
}

export default About;
