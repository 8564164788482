import { Link } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";

function CTA2({ children }) {
  return (
    <div className="cta cta-set cta-about-middle mt-5 mb-30 text-center">
      <Container>
        {children}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link
          href="/residential-home-inspections"
          className="btn btn-secondary"
        >
          Learn More
        </Link>
        <Link href="/request" className="btn cta-button">
          Schedule Now
        </Link>
      </Container>
    </div>
  );
}

export default CTA2;
