import React from "react";
import { Container } from "react-bootstrap";

function CTA3({ children }) {
  return (
    <div className="cta cta-set cta-inspections mt-5 mb-30">
      <Container>
        {children}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Container>
    </div>
  );
}

export default CTA3;
