import React from "react";
import { Card, CardColumns, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

function Locations() {
  return (
    <div>
      <Container className="mt-30">
        <div className="mb-30">
          <h3>Service Locations</h3>
        </div>
        <CardColumns className="locations">
          <Card className="locationcards">
            <Link href="/pasco">
              <StaticImage src="../images/pascohero.jpg" />
            </Link>
            <Card.Title>Pasco County</Card.Title>
          </Card>
          <Card className="locationcards">
            <Link href="/hernando">
              <StaticImage src="../images/hernandohero.jpg" />
            </Link>
            <Card.Title>Hernando County</Card.Title>
          </Card>
          <Card className="locationcards">
            <Link href="/pinellas">
              <StaticImage src="../images/pinellashero.jpg" />
            </Link>
            <Card.Title>Pinellas County</Card.Title>
          </Card>
          <Card className="locationcards">
            <Link href="/hillsborough">
              <StaticImage src="../images/hillsboroughhero.png" />
            </Link>
            <Card.Title>Hillsborough County</Card.Title>
          </Card>
          <Card className="locationcards">
            <Link href="/manatee">
              <StaticImage src="../images/annamariaisland.jpg" />
            </Link>
            <Card.Title>Manatee County</Card.Title>
          </Card>
          <Card className="locationcards">
            <Link href="/citrus">
              <StaticImage src="../images/manatee.jpg" />
            </Link>
            <Card.Title>Citrus County</Card.Title>
          </Card>
          <Card className="locationcards">
            <Link href="/polk">
              <StaticImage src="../images/polkhero.jpg" />
            </Link>
            <Card.Title>Polk County</Card.Title>
          </Card>
        </CardColumns>
      </Container>
    </div>
  );
}

export default Locations;
