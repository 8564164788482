import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaBriefcase,
  FaClipboardList,
  FaDollarSign,
  FaFontAwesomeFlag,
  FaHandPointRight,
  FaHome,
  FaRegBuilding,
  FaShieldAlt,
  FaWrench,
} from "react-icons/fa";

const Services = () => {
  return (
    <Row className="marg-0">
      <Container>
        <div className="text-center mt-50 mb-50 display-block">
          <h2>Our Services</h2>
        </div>
        <Container>
          <Row className="servicesbox ">
            <Col lg={4}>
              <div className="servicecard">
                <FaWrench className="icon" />
                <h4>Residental Pre-Purchase Inspections</h4>
                <p>
                  A comprehensive and thorough evaluation of any home you are
                  considering purchasing including: Single Family Homes, Condos,
                  Townhomes, Villas, Manufactured or Mobile Homes.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="servicecard">
                <FaDollarSign className="icon" />
                <h4>Residental Pre-Sale Inspections</h4>
                <p>
                  Don't get blindsided by things you didn't expect to be wrong
                  with your home, then have to lower your sales price or argue
                  with a potential buyer about repairs, holding up the sale of
                  your home.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="servicecard">
                <FaClipboardList className="icon" />
                <h4>11 Month Warranty Inspections</h4>
                <p>
                  Don't leave money on the table. If you have built a home in
                  the last year or two, then you know you have a builders
                  warranty on your home. It is a wise idea to have a home
                  inspection to find...
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="servicecard">
                <FaShieldAlt className="icon" />
                <h4>New Construction Phase Inspections</h4>
                <p>
                  This is an invaluable service for anyone who is looking to
                  have a reliable and nonbiased professional opinion regarding
                  the quality of installation practices being utilized during
                  construction.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="servicecard">
                <FaFontAwesomeFlag className="icon" />
                <h4>Commercial Building Inspections</h4>
                <p>
                  We also perform commercial building inspections and
                  limited-scope inspection of office buildings, strip malls,
                  apartment buildings, condo complexes, warehouses, and
                  industrial facilities.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="servicecard">
                <FaHandPointRight className="icon" />
                <h4>Home Maintenance Inspections</h4>
                <p>
                  This report will inform you on the present condition of your
                  home. This will provide insight into what needs immediate
                  attention and what items may possibly need to be budgeted for
                  the future.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="servicecard">
                <FaBriefcase className="icon" />
                <h4>Termite Inspections / WDO Inspections</h4>
                <p>
                  Florida is known for termites, both subterranean and drywood,
                  as well as certain wood destroying organisms (WDO). It is a
                  good idea to find out if the home you are purchasing is in
                  jeopardy from...
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="servicecard">
                <FaHome className="icon" />
                <h4>Mold Inspections</h4>
                <p>
                  We are licensed and certified to take air quality and swab
                  samples to test the air for any health threatening bad mold
                  that might be present anywhere in the home.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="servicecard">
                <FaRegBuilding className="icon" />
                <h4>Insurance Inspections / Reports</h4>
                <p>
                  We are certified in the completion of all your insurance
                  report needs. Our high quality services include 4-Point
                  inspections, Wind Mitigation inspections, and Roof
                  Certifications.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </Row>
  );
};

export default Services;
