import React from "react";
import { Container } from "react-bootstrap";

const Abouthero = () => {
  return (
    <div className="overlay-hero banner banner-page banner-about mb-30">
      <Container>
        <div className="overlay-herotxt">
          <h2 className="banner-head ">About Young Home Inspections</h2>
        </div>
        <div className="overlay-herosub">
          <p>Over 30 Years of Outstanding Service</p>
        </div>
      </Container>
    </div>
  );
};

export default Abouthero;
